import React from 'react';
import PropTypes from 'prop-types';

import Description from '@/components/Description';

import Item from './Item';

const Faq = ({ pageContext }) => {
  const { title, sections } = pageContext;

  return (
    <>
      <Description title={title} />
      {sections.map(({ name, texts, link }) => (
        <Item key={name} name={name} texts={texts} link={link} />
      ))}
    </>
  );
};

Faq.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        texts: PropTypes.arrayOf(PropTypes.shape({})),
        link: PropTypes.objectOf(PropTypes.string.isRequired),
      }).isRequired
    ).isRequired,
  }).isRequired,
};

export default Faq;
export { default as Head } from '@/components/Head';
