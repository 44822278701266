/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, Fragment } from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import Arrow from '@/icons/Arrow';
import CollapseIcon from '@/icons/CollapseIcon';
import DeployIcon from '@/icons/DeployIcon';

import { boxVariants } from './utils';
import useStyles from './styles';

const Item = ({ name, texts, link }) => {
  const classes = useStyles();
  const [pose, setPose] = useState('close');

  const handleClick = useCallback(() => {
    setPose(pose === 'close' ? 'open' : 'close');
  }, [pose]);

  return (
    <div className={classes.container}>
      <button className={classes.button} type="button" onClick={handleClick}>
        <p className={classes.title}>{name}</p>
        <p className={classes.signDesktop}>{pose === 'close' ? '+' : '-'}</p>
        <div className={classes.signMobile}>{pose === 'open' ? <CollapseIcon /> : <DeployIcon />}</div>
      </button>
      <motion.div className={classes.box} variants={boxVariants} animate={pose} initial="close">
        <div className={classes.contentContainer}>
          {texts &&
            texts.map(({ question, answers }, index) => (
              <Fragment key={index}>
                <h3 className={classes.question}>{question}</h3>
                {answers.map((text, idx) => (
                  <p key={idx} className={classes.answer}>
                    {text}
                  </p>
                ))}
              </Fragment>
            ))}
          {link && (
            <Link to={link.path} className={classes.link}>
              <Arrow />
              <span>{link.name}</span>
            </Link>
          )}
        </div>
      </motion.div>
    </div>
  );
};

Item.defaultProps = {
  texts: null,
  link: null,
};

Item.propTypes = {
  name: PropTypes.string.isRequired,
  texts: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ),
  link: PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
};

export default Item;
