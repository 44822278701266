import { createUseStyles } from 'react-jss';

export default createUseStyles(({ components, spacing, colors, fonts }) => {
  const { background } = components.dropDown;
  const { primary, hover, click } = colors.link;

  return {
    container: {
      margin: [0, spacing * 2],
      '&:last-child': {
        paddingBottom: spacing * 15,
      },
      '@media (max-width: 799px)': {
        margin: 0,
        '&:nth-child(2)': {
          marginTop: spacing * 2,
        },
        '&:last-child': {
          paddingBottom: spacing * 20,
        },
      },
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      height: spacing * 11,
      width: '100%',
      padding: [0, spacing * 5],
      marginTop: spacing * 1.5,
      background,
      outline: 'none',
      border: 'none',
      cursor: 'pointer',
      boxShadow: [0, 2, 23, 0, 'rgba(204, 204, 204, 0.5)'],
      '@media (max-width: 799px)': {
        height: spacing * 8.25,
        justifyContent: 'space-between',
        margin: 0,
        padding: [0, spacing * 2],
        boxShadow: [0, 2, 23, 0, 'rgba(204, 204, 204, 0.5)'],
      },
    },
    title: {
      flex: [1, 1, 'auto'],
      ...fonts.subtitle,
      textAlign: 'left',
      '@media (max-width: 799px)': {
        ...fonts.subtitleMedium,
        textAlign: 'center',
        paddingRight: spacing * 2,
      },
    },
    signDesktop: {
      ...fonts.subtitleSmall,
      width: 10,
      marginLeft: spacing * 4,
      '@media (max-width: 799px)': {
        display: 'none',
      },
    },
    signMobile: {
      '@media (min-width: 800px)': {
        display: 'none',
      },
    },
    box: {
      overflow: 'hidden',
      height: 0,
      opacity: 0,
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: [spacing * 4, 0, spacing * 0.5, 0],
      '@media (max-width: 799px)': {
        padding: [spacing * 4, spacing * 1.5, spacing * 2, spacing * 1.5],
      },
    },
    question: {
      ...fonts.subtitleBold,
      width: '100%',
      maxWidth: 420,
      textAlign: 'left',
      '@media (max-width: 799px)': {
        maxWidth: 'none',
      },
    },
    answer: {
      maxWidth: 420,
      ...fonts.subtitle,
      marginBottom: '1em',
      '@media (max-width: 799px)': {
        maxWidth: 'none',
      },
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      ...fonts.title,
      color: primary,
      textDecoration: 'none',
      marginTop: spacing * -1.5,
      '&>span': {
        marginLeft: spacing * 1.25,
      },
      '&>svg': {
        minWidth: 30,
      },
      '& *': {
        transition: 'all .3s',
      },
      '&:hover': {
        '& *': {
          color: hover,
        },
      },
      '&:active': {
        '& *': {
          color: click,
        },
      },
      '@media (max-width: 799px)': {
        ...fonts.subtitle,
        color: primary,
        '&>svg': {
          transform: 'scale(0.8)',
        },
        '&>span': {
          marginLeft: spacing * 2,
        },
      },
    },
  };
});
